body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiCardHeader-root {
  direction: rtl;
  display: flex;
  justify-content: flex-end;
}

.MuiCardHeader-root .MuiCardHeader-avatar {
  margin-right: 0;
}

.MuiCardHeader-root .MuiCardHeader-action {
  margin: 0;
  align-self: center;
}
