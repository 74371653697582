.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

table {
  color: #71ccdf !important;
  width: 50%;
}

td {
  width: 50%;
}

td {
  text-align: center;
}

table,
th,
td {
  border: 1px solid;
}
